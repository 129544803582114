/*logo*/
.letter-logo {
  display: flex;
}

.letter-logo span {
  margin-top: -69px;
  color: rgb(8, 93, 121);
  font: 200px/1 'TeXGyreAdventorBold';
  animation: 55s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s infinite both logoAnimation;
  text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc,
    0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc,
    0 9px 0 #cccccc, 0 25px 15px rgb(0, 126, 199);

}

.letter-logo h3 {
  color: rgba(106, 185, 231, 0.479);
  font-size: 30px;
  width: 12px;
  font-family: 'Roboto', sans-serif !important;
  font-weight: lighter;
  margin-top: 2px;
  margin-left: -93px;
}

.rope {
  font-family: 'Roboto', sans-serif !important;
  margin-top: -55px;
  margin-left: 65px;
  font-size: 40px;
  height: fit-content;
  font-weight: lighter;
  color: rgb(255, 255, 255);
  animation: 55s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s infinite both logoAnimation;

}



@keyframes logoAnimation {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}





#sidebar {
  position: relative;
  width: 20%;
  background-image: url(../assets/parallax/fotoParallax03.png);
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

#sidebar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #0d2e3f, #020c13);
  z-index: -1;
}

.fixedcontainer {
  position: fixed;
}

#container-logo-Nav {
  padding-top: 20%;
}




li {
  text-transform: uppercase;
  margin-top: 10%;
  list-style: none;

}

.button-navegacion {
  position: relative;
  background-color: #11455f7e;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  padding: 35%;
  left: -50%;
}

a {
  text-decoration: none;
}

.menu a:link,
a:visited {
  border-radius: 5px;
  color: white;
  font-weight: bold !important;
}

.button-navegacion-active {
  position: relative;
  padding: 35%;
  left: -50%;
  background-color: #020c1300!important;
}


 a:hover {
 
  color: rgba(199, 138, 32, 0.875) !important;
}

.menu li:hover{
  background-color: #0e65a481;
}


@media screen and (max-width: 1123px) {
  #sidebar {
    width: 0px;
    padding-left: 0px;
    padding-top: 0px;

  }

  #title2 {
    margin: 0%;
  }

  #sidebar {
    display: none;
  }

  #container-logo-Nav {
    display: none;
  }


}