@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


#tituloServicio {
  background-color: rgb(8, 93, 121);
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  padding: 10px;
  text-align: center;
  margin-top: 0%;
}

#containerServices {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}


#separator-line-FinalServices {
  height: 45px;
  width: 100%;
  background: linear-gradient(to bottom, rgb(8, 93, 121), #000000, #000000);
  margin-top: 30px;
  margin-bottom: -15px;
  animation: moveGradient 5s linear infinite;
  background-size: 200% 100%;
}

.sucontainerServices {
  width: 30%;
  font-family: "Kumbh Sans", sans-serif;
  padding: 20px;
  font-size: 13px;
  font-weight: 600;
  color: black;
}

.sucontainerServices>p {
  text-align: justify;
}

.sucontainerServices>h2 {
  color: rgb(3, 103, 121);
  font-weight: bolder;
}

.sucontainerServices img {
  width: 50px;
  height: 50px;

}

@media screen and (max-width: 1123px) {

  #tituloServicio {
    justify-content: center;
    align-items: center;

  }

  #containerServices {
    display: grid;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .sucontainerServices {
    background-color: rgb(4, 85, 109);
    color: rgb(255, 255, 255);
    border: 15px solid rgba(0, 0, 0, 0.212);
    border-radius: 25px;
    box-shadow: 12px 12px 12px 2px rgba(0, 0, 0, 0.521);
    margin-bottom: 5%;
    width: 75%;
    padding: 5%;
    margin-left: 3.5%;
  }

  .sucontainerServices>h2 {
    color: rgba(255, 255, 255, 0.411);
    font-weight: bolder;
  }

  .sucontainerServices p {
    color: rgb(255, 255, 255);
    border-radius: 15px;
  }


  .sucontainerServices h2:hover {
    color: rgba(0, 0, 0, 0.788);
  }


  .sucontainerServices img {
    margin-left: 0%;
    width: 50px;
    height: 50px;

  }

  .sucontainerServices:hover {
    color: white;
    background-color: #03685b41;
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #aac7d1d0 10px), repeating-linear-gradient(#085d792c, #085d7942);
  }

  .sucontainerServices:hover p {
    background-color: rgba(4, 84, 109, 0.342);
  }





}