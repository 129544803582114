@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100&display=swap");






.bento-grid-general {
  border-radius: 25px;
  margin: 1%;
  padding: 1% 5% 1% 5%;

  background-color: rgb(6, 47, 61);
}

#bento-grid-container {
  display: grid;
  grid-template-columns: 20% 30% 30% 20%;
  background-color: rgb(255, 255, 255);
}


#bento-grid-Linkedin {
  grid-column: 1/2;
  perspective: 1000px;
  overflow: hidden;
  margin-top: 4%
}

.img-linkedin {
  position: absolute;
  border-radius: 15px;
  top: 25%;
  left: 20%;
  width: 60%;
  height: 50%;
}

#imageLinkedin {
  opacity: 1;
  box-shadow: 0 20px 25px rgba(0, 0, 0, .5), inset 0 2px 2px #525154;
  backface-visibility: hidden;
}

#imageLinkedin2 {
  opacity: 0;
  width: 100%;
  height: 100%;
}

#imageLinkedin-Profile {
  box-shadow: 0 20px 25px rgba(0, 0, 0, .5), inset 0 2px 2px #525154;
  display: none;
}

#bento-grid-Linkedin:hover #imageLinkedin {
  animation: flip 0.4s alternate both;
}

#bento-grid-Linkedin:hover #imageLinkedin-Profile {
  animation: flip 0.4s alternate both;
  display: inline;
}

#bento-grid-Linkedin:not(:hover) #imageLinkedin {
  animation: flip-return 0.3s alternate both;
  opacity: 1;

}

@keyframes flip {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(180deg);
  }
}

@keyframes flip-return {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(180deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}














#bento-grid-Presentation {
  grid-column: 2/4;
  background-image: url(../assets/imageLikePinted2.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
}

#bento-grid-Presentation>h2 {
  font-size: 32px;
  color: beige;
  font-weight: 900;
  margin-right: 25%;
}

#bento-grid-Presentation>p {
  font-size: 19px;
  color: white;
  margin-right: 25%;
  text-align: justify;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}

#bento-grid-Presentation>p>span {
  color: #00d9ffb7;
  font-weight: 400;
}














#bento-grid-Maps {
  grid-column: 4/5;
  background-image: url(../assets/map.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70%;
  justify-content: center;
  margin-top: 4%
}

#bento-grid-Maps>#ping>img {
  color: white;
  animation: ping 0.8s ease-in-out infinite both;
}

#circle {
  position: absolute;
  margin-top: 2.1%;
  margin-left: 0.4%;
  width: 5%;
  height: 8%;
  z-index: 5;

}

#imagePortratiGoogle {
  position: absolute;
  margin-top: 3%;
  margin-left: 1.9%;
  width: 2%;
  height: 4%;
  z-index: 6;
}

#imagePortratiGoogle:hover {
  animation: flip 0.4s alternate both;

}

#imagePortratiGoogle:hover+#dialog {
  opacity: 1;
  transition: opacity 2s ease;
}

#dialog {
  opacity: 0;
  position: absolute;
  margin-top: 0.5%;
  margin-left: 4%;
  width: 5%;
  height: 7%;
}

@keyframes ping {
  0% {

    transform: scale(0.2);
    opacity: 0.8;
  }

  80% {

    transform: scale(1.2);
    opacity: 0;
  }

  100% {

    transform: scale(2.2);
    opacity: 0;
  }
}
















#bento-grid-Carrousel {
  grid-column: 1/3;

}

#box-carousel p {
  width: fit-content;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 17px;
  text-align: left;
  color: rgb(255, 255, 255);
}

#carousel2 img {
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding: 5%;
  width: 70px;
  height: 70px;
}

#carousel2 img:hover {
  animation: jello-vertical 0.9s both;
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(0.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, 0.75, 1);
  }

  50% {
    transform: scale3d(0.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, 0.95, 1);
  }

  75% {
    transform: scale3d(0.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.custom-carousel .slick-prev,
.custom-carousel .slick-next {
  display: none !important;
}

#separator-line3 {
  border-radius: 3px;
  height: 17px;
  width: 100%;
  background: linear-gradient(to left, rgb(113, 219, 255), #000000);
  margin-top: 10px;
  animation: moveGradient 10s linear infinite;
  background-size: 200% 100%;
}


@keyframes moveGradient2 {
  0% {
    background-position: 0% 100%;
  }

  25% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 100% 0%;
  }

  75% {
    background-position: 50% 50%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes moveGradient {
  0% {
    background-position: 100% 0%;
  }

  90% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 100% 0%;
  }
}















#bento-grid-AboutMe {
  grid-column: 3/5;
}

#bento-grid-AboutMe>p {
  padding: 2%;
  font-size: 17px;
  color: white;
  text-align: justify;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}














#bento-grid-Git {
  grid-column: 2/3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/matrix.gif);
}

#bento-grid-Git>a:hover {
  box-shadow: 0 20px 20px -18px rgb(6, 236, 75);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#iconoGithub {
  height: 100px;
  width: 80px;
  border-radius: 15px;
}

















#bento-grid-English {
  grid-column: 1/2;
  text-align: center;
  color: ghostwhite;
  padding: 8%;
}

#bento-grid-English>span {
  font-weight: 900;
}

#bento-grid-English>h1 {
  font-size: 5rem;
  margin: 0%;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif
}

#bento-grid-English>h1:hover {
  animation: shake 0.8s both;
  cursor: default;
}

@keyframes shake {

  0%,
  100% {

    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {

    transform: translateX(-30px) rotate(-6deg);
  }

  30% {

    transform: translateX(15px) rotate(6deg);
  }

  45% {

    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {

    transform: translateX(9px) rotate(2.4deg);
  }

  75% {

    transform: translateX(-6px) rotate(-1.2deg);
  }
}













#bento-grid-Time {
  padding: 0%;
  height: fit-content;
  grid-column: 3/4;
  position: relative;
}

#bento-grid-Weather {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
}

#bento-grid-Picture {
  grid-column: 4/5;
  background-image: url(../assets/imageinWork.png);
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0%;
  margin: 0%;
  overflow: hidden;
}

#bento-grid-Picture>div:hover {
  animation: slide-out-top 4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

#bento-grid-Picture>div:not(:hover) {
  animation: slide-out-down 8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes slide-out-top {
  0% {

    transform: translateY(0);
    opacity: 1;
  }

  100% {

    transform: translateY(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-down {
  0% {

    transform: translateY(-5px);
    opacity: 0;
  }

  100% {

    transform: translateY(0);
    opacity: 1;
  }
}

#bento-grid-Picture>div {
  height: 100%;
  width: 100%;
  border-radius: 25px;
  background-size: cover;
  background-image: linear-gradient(to top, rgba(8, 71, 122, 0.116), rgba(0, 31, 59, 0.671), rgb(0, 31, 59));
}
























#separator-line2 {
  border-top: 4px solid rgb(8, 93, 121);
  margin: 14px 91px 0px 91px;
}





#container-logo-Initiation {
  display: none;
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {


  #bento-grid-Presentation>h2 {

    font-size: 22px;
    margin-right: 35%;

  }

  #bento-grid-Presentation>p {
    font-size: 13px;
  }

  #bento-grid-AboutMe>p {
    font-size: 13px;
  }

}

@media screen and (max-width: 1500px) {


  #bento-grid-Presentation>h2 {
    font-size: 21px;
    margin-right: 35%;
  }

  #bento-grid-Presentation>p {
    font-size: 13px;
  }

  #bento-grid-AboutMe>p {
    font-size: 13px;
  }

}

@media screen and (max-width: 1250px) {

  #bento-grid-Presentation>h2 {
    font-size: 18px;
    margin-right: 35%;
  }


  #bento-grid-Presentation>p {
    font-size: 14px;
    margin-right: 35%;
  }

  #bento-grid-AboutMe>p {
    font-size: 12px;
  }

}



@media screen and (max-width: 1123px) {

  #separator-line1 {
    display: none;
  }

  #separator-line2 {
    border-top: 8px solid rgb(8, 93, 121);
    margin: 25px 0px 0px 0px !important;
  }


  #container-logo-Initiation {
    display: inline;
    position: absolute;
    margin-top: -12%;
    -webkit-text-stroke: 1px rgba(223, 234, 238, 0.527);

  }

  #container-logo-Initiation span {
    font-size: 150px;
    color: rgba(238, 238, 238, 0.836);

    text-shadow: 0 1px 0 #0475a1, 0 2px 0 #0475a1, 0 3px 0 #0475a1, 0 4px 0 #0475a1, 0 5px 0 #0475a1, 0 6px 0 #0475a1, 0 7px 0 #0475a1, 0 8px 0 #0475a1, 0 9px 0 #0475a1, 10px 25px 15px rgb(0, 0, 0);
  }

  .rope {
    margin-left: 50px !important;
  }

  #container-logo-Nav {
    display: none;
  }

  @media screen and (max-width: 600px) {
    #container-logo-Initiation {
      display: none;
    }

    #bento-grid-container {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      background-image: url(../assets/parallax/fotoParallax01.png);
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      margin: 0%;
      border-radius: 0%;
    }

    #bento-grid-Linkedin {
      display: flex;
      align-items: center;
      grid-column: 3/4;
      order: 2;
      perspective: 0px;
      height: 100%;
    }

    .img-linkedin {
      position: absolute;
      width: 80%;
      height: 80%;
      top: 10%;
      left: 10%;
    }

    #bento-grid-Presentation {
      grid-column: 1/4;
      order: 1;
      background-position-x: 124%;
      margin-bottom: 2%;
    }

    #bento-grid-Presentation>h2 {
      text-align: justify;
      font-size: 19px;
    }

    #bento-grid-Presentation>p {
      font-size: 13px;
      text-align: left;
    }

    #bento-grid-Maps {
      grid-column: 1/3;
      order: 1;
      width: 80%;
      height: 90%;
    }

    #circle {
      margin-top: 0%;
      margin-left: 9%;
      width: 19%;
      height: 4%;
    }

    #imagePortratiGoogle {
      margin-top: 0%;
      margin-left: 15%;
      width: 7%;
      height: 4%;
    }

    #bento-grid-Carrousel {
      grid-column: 1/4;
      order: 4;
      margin: 5% 0% 3% 0%;
    }

    #carousel2 img {
      border-radius: 5px;
      width: 50px;
      height: 50px;
    }

    #bento-grid-AboutMe {
      grid-column: 1/4;
      order: 5;
      margin-bottom: 3%;
    }

    #bento-grid-English {
      grid-column: 1/2;
      order: 6;
      margin: 2%;
    }

    #bento-grid-English>h1 {
      font-size: 4rem;

    }

    #bento-grid-Git {
      grid-column: 2/3;
      order: 7;
      margin: 4%;
    }

    #bento-grid-Picture {
      grid-column: 3/4;
      order: 7;
      margin: 2%;
    }


    #bento-grid-Time {
      grid-column: 1/4;
      order: 8;
      margin: 2%;

    }

    .buscador-contenedor {
      text-align: center;
      justify-content: center;
      align-items: center;
      background-color: rgb(0, 0, 0);
      height: 25%;
      width: 50%;

    }

    .buscador-bar {
      border-radius: 5px !important;
      height: 80%;


    }
  }

}