@keyframes animationUp {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.filtered {
  opacity: 0;
  transition: opacity 1.5s ease, transform 0.5s ease;
}

.image-container {
  overflow-x: hidden;
  overflow-y: scroll;
  overflow: auto;
  text-align: center;
  max-width: 80%;
}

.imageAnimation1 {
  animation: animationUp 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.imageAnimation2 {
  animation: animation 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.image-container img {
  width: 245px !important;
  height: 190px !important;
  border-radius: 25px;
  margin: 0.2%;
}

.image-container img:hover {
  cursor: pointer;
}

button {
  display: inline-block;
  padding: 2px 20px;
  font-size: 10px;
  text-align: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 15px;
}

.button-galeria {
  margin-top: 2%;
  margin-bottom: -23px;
  color: transparent;
  background-color: transparent;
}


button:hover {
  color: white;
  font-size: 10px;
}


button:disabled {
  color: rgb(255, 255, 255);
  opacity: 0.2;
}

.icono {
  width: 50px;
  height: 50px;
}

#trabajos {
  min-height: 450px;
  max-height: 450px;
  padding: 15px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(8, 93, 121, 0.281);
  background-color: rgb(3, 25, 32);
}

#linea-separadora-galeria {
  display: flex;
  height: 10%;
  background-color: rgb(8, 93, 121);
  box-shadow: 0px 8px 5px 1px rgba(0, 0, 0, 0.616);
  text-align: center;
  justify-content: center;
}

.popup {
  border-radius: 15px;
  position: fixed;
  width: 25%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(10, 36, 44, 0.938);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.popup img {
  width: 100% !important;
  height: 100% !important;
}

#buttonCerrar {
  border-radius: 5px;
  align-self: flex-end;
  background: rgba(8, 93, 121, 0.486);
  color: white;
  font-size: 15px;
  border-color: #CF2A2A;
  box-shadow: 0 0 30px 30px rgb(65, 182, 221) inset;
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: 12px;
}

#buttonCerrar:hover {
  box-shadow: 0 0 12px 0 #CF2A2A inset, 0 0 12px 4px #CF2A2A;
}

.textoImagen {
  color: white;
}

.popup h4 {
  background-color: rgb(65, 182, 221);
  border-radius: 5px;
  color: black;
  width: fit-content;
  padding: 8px;
}

.popup p {
  color: white;
  font-weight: 600;

}

.popup a {
  color: white;
  font-size: 15px;
  padding: 5px;
  border-radius: 10px;
  border-color: #FF8B25;
  position: relative;
  overflow: hidden;
  text-shadow: 2px 2px 2px black;
  z-index: 1;
}

.popup a:hover:after {
  width: 110%;
}

.popup a:after {
  border-radius: 7px;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 100%;
  background-color: rgb(8, 93, 121);
  z-index: -1;
  transition: width 0.3s ease-in-out;

}

@media screen and (max-width: 1123px) {
  #nav-galeria {
    width: 100%;
  }

  .popup {
    width: 80%;
    height: auto;
  }

  .image-container {
    overflow: scroll;
    text-align: center;
    max-width: 100%;
    overflow-x: hidden;

  }

  #trabajos {
    min-height: 350px;
    max-height: 400px;
  }

}

@media screen and (min-width: 1123px)and (max-width: 1300px) {
  .image-container img {
    width: 178px !important;
    height: 140px !important;
  }
}

@media screen and (min-width: 414px) and (max-width: 1122px) {
  .image-container img {
    width: 160px !important;
    height: 140px !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 412px) {
  .image-container img {
    width: 140px !important;
    height: 120px !important;
  }
}