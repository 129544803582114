body {
	font-family: 'Kumbh Sans', system-ui, -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Open Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
}


.general {
	display: flex;
	width: 100%;
	height: 100%;
}



.content {

	align-items: stretch;
	flex-wrap: wrap;
	width: 70%;
	height: 100%;
}


@media screen and (max-width: 1123px) {
	.content {
		width: 100%;
	}

	div.eapps-widget {
		display: none;
	}
}