#contacto-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #020c13;
  padding: 1%;
}


#redes-sociales {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding: 2px;
  justify-content: space-around;
  width: 100%;
}


#redes-sociales a {
  font-size: 20px;
  color: #ffffff !important;
  border-radius: 5px;
  font-weight: normal !important;
  padding: 15px;
  background-color: rgba(8, 93, 121, 0.295);
}

#redes-sociales a:hover {
  background-image: url(../assets/parallax/fotoParallax03.png);
  background-position: 50%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #000000 !important;
  font-weight: 300 !important;
}



#redes-sociales button {
  color: #2980b9;
  border-radius: 65px !important;
  width: 50%;
  margin-left: 23%;
  /*background-image: url(../assets/parallax/fotoParallax03.png);
  background-position: 50%;*/
}


#redes-sociales button:hover {
  color: #0575b6 !important;
  background-image: none;
  background-color: rgb(255, 255, 255);
}

#buttonCV {
  position: relative;
  text-align: center;
}

#buttonCV::before {
  content: attr(data-original-text);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

/* Cambiar el texto cuando se hace hover */
#buttonCV:hover::before {
  font-size: 20px;
  content: "Curriculum";

}

#auto-write {
  font-size: 140px;
  font-family: 'Impact', sans-serif;
  color: rgba(33, 118, 161, 0.247);
  white-space: nowrap;
  overflow: hidden;
}

#buttonCV {
  font-size: 35px;
  font-weight: bold;
  color: #000000 !important;
  background-size: cover;
  background-image: url(../assets/ojo2.png);
}


.typed-cursor {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.493);
}


@media screen and (max-width: 700px) {

  #auto-write,
  .typed-cursor {
    font-size: 118px;
  }
}

@media screen and (max-width: 700px) {

  #auto-write,
  .typed-cursor {
    font-size: 90px;
  }
}



@media screen and (max-width: 600px) {


  #auto-write,
  .typed-cursor {
    font-size: 60px;
  }


}