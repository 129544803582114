body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;

  /* Añade display: flex para que #root ocupe toda la altura */
}


.parallax {
  width: 15%;

}


@media screen and (max-width: 1123px) {
  .parallax {
    width: 0%;
    display: none;
  }


}