.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-height: 100px;
    line-height: 10px;
    text-align: center;
    font-size: 9px;
    padding-top: 1%;
    color: white;
    background-color: #020c13;

}

#buttontoTop {
    border-radius: 0px 0px 20px 20px;
    background: rgba(8, 93, 121, 0.849);
    color: white;
    color: white;
    font-size: 19px;
    border-color: #CF2A2A;
    box-shadow: 0 0 30px 30px rgb(3, 102, 134) inset;
    transition: box-shadow 0.3s ease-in-out;
    transform: rotateX(180deg);
}

#buttontoTop:hover {
    box-shadow: 0 0 12px 0 #CF2A2A inset, 0 0 12px 4px #CF2A2A;
}

#footerText {
    padding: 0px 25px 2px 25px;
    width: fit-content;
    border-radius: 10px;
    text-align: center;
}

#footerText>h3 {
    color: rgb(67, 147, 173);

}

#footerText>h4 {
    color: rgba(12, 114, 114, 0.644);
}

@media screen and (max-width: 1123px) {
    .footer {
        width: 100%;

    }
}